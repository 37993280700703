/**
 * Video
 */

import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useInView } from 'react-hook-inview';
import { Video as V } from '@components';
import css from './Video.module.css';

const Video = ({ video: video_path }) => {
  const [inView, setInView] = useState(false);
  const [anchorRef] = useInView({
    onEnter: () => setInView(true),
    onLeave: () => setInView(false),
  });

  if (!video_path) {
    return null;
  } 

  return (
    <div className="page__block--video">
      <div className={css.anchor} ref={anchorRef} />
      <div className="video">
        <div className="video__video">
          <V inView={inView} autoPlay loop muted playsInline path={video_path} />
        </div>
      </div>
    </div>
  );
}

export default Video;

export const pageBuilderVideoQuery = graphql`
  fragment PageBuilderVideo on WordPressAcf_video {
    ... on WordPressAcf_video {
      video
    }
  }
`